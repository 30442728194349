import {
  COMPANY_NAME,
  IMG_LINK_LIST,
  PREFIX_CANONICAL_URL,
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from 'components/constant';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';
import {
  HomePageStructuredDataType,
  LocalBusinessType,
  OrganizationType,
  WebSiteType,
} from 'components/structuredData/types';

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const LandingPageStructuredData = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSocialMediaPrompt {
        nodes {
          SocialNetworkingPlatform {
            Link
          }
        }
      }
      allStrapiLandingPage {
        nodes {
          LocalBusinessStructuredData {
            description
            streetAddress
            addressLocality
            postalCode
            addressCountry
            telephone
            openingHours
            slogan
            image {
              url
            }
          }
        }
      }
    }
  `);

  const { SocialNetworkingPlatform } = data.allStrapiSocialMediaPrompt.nodes[0];
  const {
    description,
    streetAddress,
    addressLocality,
    postalCode,
    addressCountry,
    telephone,
    openingHours,
    slogan,
    image: { url: localBusinessImgUrl },
  } = data.allStrapiLandingPage.nodes[0].LocalBusinessStructuredData;
  const socialList = SocialNetworkingPlatform.map(
    ({ Link }: { Link: string }) => Link,
  );

  const organization: OrganizationType = {
    '@type': StructuredDataTypes.ORGANIZATION,
    '@id': `${PREFIX_CANONICAL_URL}#organization`,
    name: COMPANY_NAME,
    url: PREFIX_CANONICAL_URL,
    sameAs: socialList,
    logo: IMG_LINK_LIST.logo,
  };

  const localBusiness: LocalBusinessType = {
    '@type': StructuredDataTypes.LOCAL_BUSINESS,
    name: COMPANY_NAME,
    image: localBusinessImgUrl,
    address: {
      '@type': StructuredDataTypes.POSTAL_ADDRESS,
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry,
    },
    telephone,
    openingHours,
    url: PREFIX_CANONICAL_URL,
    description,
    sameAs: socialList,
    slogan,
    logo: IMG_LINK_LIST.logo,
  };

  const webPage: WebSiteType = {
    '@type': StructuredDataTypes.WEB_SITE,
    '@id': `${PREFIX_CANONICAL_URL}#website`,
    name: COMPANY_NAME,
    url: PREFIX_CANONICAL_URL,
  };

  const structuredData: HomePageStructuredDataType = {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@graph': [organization, localBusiness, webPage],
  };

  return <StructuredDataScript data={structuredData} />;
};

export default LandingPageStructuredData;
