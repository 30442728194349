import Link from 'components/Link';
import React from 'react';

interface USPBlogCardProps {
  blogTitle: string;
  blogDescription: string;
  blogUrl: string;
  btn: string;
}

const USPBlogCard = ({
  blogTitle,
  blogDescription,
  blogUrl,
  btn,
}: USPBlogCardProps) => {
  return (
    <div className="px-[30px] py-4 bg-[#FAFAFA] max-w-[770px] text-accent_blue_1">
      <p className="text-[18px] md:text-[18px] font-[600] mb-2 text-hubble_blue leading-[150%]">
        {blogTitle}
      </p>
      <p className="text-[14px] lg:text-[16px] leading-[150%] text-base_text m-0">
        {blogDescription}
      </p>
      <div className="flex gap-3 items-center text-accent_blue_2 hover:text-accent_blue_2 text-[14px] md:text-[16px] font-[500] mt-[30px] lg:mt-[22px] lg:mb-[6px]">
        <Link
          to={blogUrl}
          className="text-accent_blue_2 hover:text-accent_blue_2 hover:underline text-[14px] lg:text-[16px] font-[500]"
        >
          {btn}
        </Link>
        <span className="fa-light fa-arrow-right" />
      </div>
    </div>
  );
};

export default USPBlogCard;
