import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { StatType } from './type';

interface StatProps extends StatType {}
const Stat = ({ stat, unit }: StatProps) => {
  return (
    <>
      <h3 className="text-accent_blue_2 m-0">{stat}</h3>
      <span className="md:text-[16px] text-[14px] text-base_text">{unit}</span>
    </>
  );
};

const AllStats = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          StatsAndUnits {
            header
            description
          }
        }
      }
    }
  `);
  const { StatsAndUnits } = data.allStrapiLandingPage.nodes[0];
  const StatList: StatType[] = StatsAndUnits.map(
    ({ header, description }: Record<string, string>) => ({
      stat: header,
      unit: description,
    }),
  );
  return (
    <div className="grid grid-cols-2 lg:grid-cols-5 w-fit gap-8 mt-8 mb-10 mx-auto">
      {StatList.map((stat, index) => (
        <div
          key={index}
          className={`flex flex-col justify-center
          ${index === 4 && 'col-span-full text-center lg:col-auto'}
            `}
        >
          <Stat key={stat.unit} {...stat} />
        </div>
      ))}
    </div>
  );
};

export default AllStats;
