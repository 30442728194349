import React from 'react';
import LanguageProvider from 'LanguageProvider';
import translation from '../translation';
import TestimonialCarousel from './TestimonialCarousel';
import { NewsroomTestimonialContentType } from '../types';

interface Props extends NewsroomTestimonialContentType {}

const index = (props: Props) => {
  return (
    <LanguageProvider messages={translation}>
      <TestimonialCarousel {...props} />
    </LanguageProvider>
  );
};

export default index;
