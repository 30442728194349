import Link from 'components/Link';
import LocationCards from 'components/locationCards';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import AllStats from './AllStats';

const StatsAndLocation = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          StatsAndLocationSubHeader
          StatsAndLocationHeader
          StatsAndLocationDescription
          StatsAndLocationButtonLabel
          StatsAndLocationButtonLink
        }
      }
    }
  `);
  const {
    StatsAndLocationSubHeader,
    StatsAndLocationHeader,
    StatsAndLocationDescription,
    StatsAndLocationButtonLink,
    StatsAndLocationButtonLabel,
  } = data.allStrapiLandingPage.nodes[0];
  return (
    <div className="text-center px-[30px] py-[60px] lg:px-0 lg:pb-[120px]">
      <p className="text-[16px] md:text-[18px] mb-2">
        {StatsAndLocationSubHeader}
      </p>
      <h3 className="mb-6">{StatsAndLocationHeader}</h3>
      <p className="b2 mb-4">{StatsAndLocationDescription}</p>
      <Link
        to={StatsAndLocationButtonLink}
        className="text-[14px] md:text-[16px] font-[500] border border-hubble_blue text-hubble_blue hover:text-white hover:bg-hubble_blue px-3 py-2"
      >
        {StatsAndLocationButtonLabel}
      </Link>

      <AllStats />
      <div className="text-start">
        <LocationCards />
      </div>
    </div>
  );
};

export default StatsAndLocation;
