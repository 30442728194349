import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { USPType } from './type';
import USPCard from './USPCard';

const USPs = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          Newsroom {
            image {
              url
            }
            SubHeader
            Header
            Description
            blogTitle
            blogDescription
            CTAButtonLabel
            CTAButtonLink
          }
        }
      }
    }
  `);
  const { Newsroom } = data.allStrapiLandingPage.nodes[0];
  return (
    <div className="flex flex-col justify-center gap-[40px] lg:gap-[80px] pt-[30px] lg:pt-[100px] lg:pb-[120px]">
      {Newsroom.map((card: Record<string, any>, index: number) => {
        const {
          image: { url: imgUrl },
          SubHeader: subTitle,
          Header: title,
          Description: description,
          blogTitle,
          blogDescription,
          CTAButtonLabel: btn,
          CTAButtonLink: blogUrl,
        } = card;
        const newsroomCard: USPType = {
          imgUrl,
          subTitle,
          title,
          description,
          blogTitle,
          blogDescription,
          btn,
          blogUrl,
        };
        return <USPCard index={index} key={index} {...newsroomCard} />;
      })}
    </div>
  );
};

export default USPs;
