import * as React from 'react';
import NewLayout from 'components/NewLayout';
import Landing from 'components/pages/landing';

import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';

const IndexPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="transparent">
        <Landing />
      </NewLayout>
    </LanguageProvider>
  );
};
export default IndexPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiLandingPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiLandingPage.nodes[0]}
      canonicalURL={`${PREFIX_CANONICAL_URL}`}
    />
  );
};

export const query = graphql`
  query {
    allStrapiLandingPage {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
