import React from 'react';
import ProductOverviewDownloadBtn from './ProductOverviewDownloadBtn';
import { ProductBenefitBrochuresType } from 'components/productTemplate/types';
import { graphql, useStaticQuery } from 'gatsby';
import tw from 'twin.macro';
import ProductsV2 from './ProductsV2';
import { ProductOverviewSystemsType } from 'components/pages/productOverview/types';

const ComponentWrapper = tw.div`
bg-accent_grey_1 pb-[60px] lg:pb-0
`;
const HeroBannerAndBrochureWrapper = tw.div`
flex flex-col gap-0 justify-center items-center text-center pt-[60px] pb-[40px] px-[30px] lg:px-0 text-subtext
`;
const Header = tw.div`
text-[24px] font-[500] lg:text-[36px] lg:font-[400] mb-2 lg:mb-6 max-w-[658px] text-base_text leading-[150%]
`;
const Description = tw.p`
mb-6 whitespace-pre-line
`;
const HeroImg = tw.img`
h-[184px] lg:h-[384px] my-10 mx-0 inline-block lg:mb-[60px]
`;
const SubHeader = tw.p`
text-[20px] font-[500] leading-[150%] lg:leading-[115%] lg:text-[24px] lg:font-[600] text-accent_blue_2 mb-0
`;

const ProductOverview = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBrochure(filter: { isProductOverviewBrochure: { eq: true } }) {
        nodes {
          image {
            url
          }
          downloadLink
          brochureName
          dataLayer
        }
      }
      allStrapiLandingPage {
        nodes {
          ProductOverviewHeader
          ProductOverviewDescription
          ProductOverviewImage {
            url
          }
          ProductOverviewBrochureCTAButton
          ProductOverviewSubHeader
          ProcessValueChainDescription {
            name
          }
          SpecificProductCard {
            icon {
              url
            }
            Only_For_DEV_labelClassName
            moreInfoButtonLabel1
            moreInfoButtonLink1
            name
            shortDescription
            status
          }
        }
      }
    }
  `);

  const { downloadLink, brochureName, dataLayer, image } =
    data?.allStrapiBrochure?.nodes?.[0];
  const brochureContent: ProductBenefitBrochuresType = {
    brochureLink: downloadLink,
    brochureName,
    dataLayer,
    brochureImg: image.url,
  };

  const {
    ProductOverviewHeader,
    ProductOverviewDescription,
    ProductOverviewImage: { url: heroImg },
    ProductOverviewBrochureCTAButton,
    ProductOverviewSubHeader,
    ProcessValueChainDescription,
    SpecificProductCard,
  } = data.allStrapiLandingPage.nodes[0];
  const productOverviewSystems: ProductOverviewSystemsType = {
    labelList: ProcessValueChainDescription.map(
      ({ name }: { name: string }) => name,
    ),
    cardList: SpecificProductCard.map(
      ({
        Only_For_DEV_labelClassName,
        icon,
        name,
        status,
        shortDescription,
        moreInfoButtonLabel1,
        moreInfoButtonLink1,
      }: Record<string, any>) => ({
        label: name,
        icon: icon.url,
        isComingSoon: Boolean(status),
        labelClassName: Only_For_DEV_labelClassName,
        description: shortDescription,
        status,
        btnLink: moreInfoButtonLink1,
        btnLabel: moreInfoButtonLabel1,
        to: null,
      }),
    ),
  };
  return (
    <ComponentWrapper className="product-overview-background ">
      <HeroBannerAndBrochureWrapper>
        <Header>{ProductOverviewHeader}</Header>
        <Description className="b2">{ProductOverviewDescription}</Description>
        <ProductOverviewDownloadBtn
          btnLabel={ProductOverviewBrochureCTAButton}
          brochureContent={brochureContent}
        />
        <HeroImg src={heroImg} alt="product mock" />
        <SubHeader>{ProductOverviewSubHeader}</SubHeader>
      </HeroBannerAndBrochureWrapper>

      <ProductsV2 {...productOverviewSystems} />
    </ComponentWrapper>
  );
};

export default ProductOverview;
