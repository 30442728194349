import Link from 'components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const StyledLink = tw(Link)`
  text-[18px] md:text-[16px] font-[500] hover:underline text-white hover:text-white
`;
const ComponentWrapper = tw.div`
  flex flex-col md:flex-row gap-6 lg:gap-4 md:gap-8 justify-between z-1 text-white px-[30px] py-6 md:py-[27px] lg:px-0 h-[170px] md:h-fit
`;
const ButtonWrapper = tw.div`
  flex gap-3 items-center justify-center self-start lg:self-center
`;
const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          HeroFooterBannerCTAHeader
          HeroFooterBannerCTAButtonLink
          HeroFooterBannerCTAButtonLabel
        }
      }
    }
  `);
  const {
    HeroFooterBannerCTAHeader,
    HeroFooterBannerCTAButtonLink,
    HeroFooterBannerCTAButtonLabel,
  } = data.allStrapiLandingPage.nodes[0];
  return (
    <ComponentWrapper>
      <p className="text-[16px] lg:text-[20px] mb-0">
        {HeroFooterBannerCTAHeader}
      </p>
      <ButtonWrapper>
        <StyledLink to={HeroFooterBannerCTAButtonLink}>
          {HeroFooterBannerCTAButtonLabel}
        </StyledLink>
        <span className="fa-light fa-arrow-right" />
      </ButtonWrapper>
    </ComponentWrapper>
  );
};

export default Banner;
