import React from 'react';
import LandingHero from './LandingHero';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import Banner from './Banner';
import RequestForDemoCard from 'components/requestForDemo/RequestForDemoCard';
import Environment from './Environment';
import Benefits from './benefits';
import USPs from './USPs';
import StatsAndLocation from './statsAndLocation';
import TestimonialCarousel from 'components/testimonial/carousel';
import ProductOverview from './productOverview';
import LayoutContainer from 'components/LayoutContainer';
import { graphql, useStaticQuery } from 'gatsby';
import { NewsroomTestimonialContentType } from 'components/testimonial/types';
import { DemoCardType } from 'components/requestForDemo/type';
import Certification from './Certification';
import LandingStructuredData from './LandingPageStructuredData';

const Landing = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          CollateralReferencesHeader
          CollateralReferencesSubHeader
          CollateralReferencesCTAButtonLink
          CollateralReferencesCTAButtonLabel
          LeaveAReviewCTAButton
          CustomerCard {
            name
            title
            testimonial
            image {
              url
            }
          }
          RequestForDemoCard {
            PageCTAHeader
            PageCTAEmphaticDescription
            PageCTADescription
            image {
              url
            }
            CTAButton {
              label
              link
              type
            }
          }
        }
      }
    }
  `);
  const {
    CollateralReferencesHeader,
    CollateralReferencesSubHeader,
    CollateralReferencesCTAButtonLink,
    CollateralReferencesCTAButtonLabel,
    LeaveAReviewCTAButton,
    CustomerCard,
    RequestForDemoCard: StrapiRequestForDemoCard,
  } = data.allStrapiLandingPage.nodes[0];

  const testimonialCarouselContent: NewsroomTestimonialContentType = {
    description: CollateralReferencesSubHeader,
    title: CollateralReferencesHeader,
    btnLabel: CollateralReferencesCTAButtonLabel,
    btnLink: CollateralReferencesCTAButtonLink,
    reviewBtnLabel: LeaveAReviewCTAButton,
    cardList: CustomerCard.map(
      ({ name, title, testimonial, image }: Record<string, any>) => ({
        imgUrl: image.url,
        author: name,
        projectName: title,
        content: testimonial,
      }),
    ),
  };

  const {
    PageCTAHeader,
    PageCTAEmphaticDescription,
    PageCTADescription,
    image: { url: requestDemoImage },
    CTAButton,
  } = StrapiRequestForDemoCard;
  const requestForDemoContent: DemoCardType = {
    title: PageCTAHeader,
    strongDescription: PageCTAEmphaticDescription,
    description: PageCTADescription,
    imgUrl: requestDemoImage,
    navBtn: CTAButton.map(({ label, link, type }: Record<string, string>) => ({
      label,
      to: link,
      type,
    })),
  };
  return (
    <LanguageProvider messages={translation}>
      <LandingStructuredData />
      <LandingHero />
      <div className="bg-accent_blue_2">
        <LayoutContainer>
          <Banner />
        </LayoutContainer>
      </div>

      <LayoutContainer>
        <Benefits />
      </LayoutContainer>
      <div className="bg-accent_grey_1">
        <Environment />
        <LayoutContainer>
          <ProductOverview />
        </LayoutContainer>
      </div>
      <div className="bg-accent_blue_2">
        <LayoutContainer className="px-[30px] lg:px-0">
          <Certification />
        </LayoutContainer>
      </div>
      <LayoutContainer>
        <USPs />
      </LayoutContainer>

      <div className="bg-accent_grey_1">
        <LayoutContainer>
          <TestimonialCarousel {...testimonialCarouselContent} />
        </LayoutContainer>
      </div>

      <LayoutContainer>
        <StatsAndLocation />
      </LayoutContainer>
      <div className="bg-accent_grey_1">
        <LayoutContainer>
          <RequestForDemoCard {...requestForDemoContent} />
        </LayoutContainer>
      </div>
    </LanguageProvider>
  );
};

export default Landing;
