import React from 'react';
import { ReviewCardType } from '../types';

const HorizontalReviewCard = ({
  imgUrl,
  projectName,
  content,
  author,
}: ReviewCardType) => {
  return (
    <div className="py-[24px] lg:py-[30px] px-6 md:px-[30px] h-[660px] w-[354px] md:h-[366px] md:w-[720px] flex flex-col md:flex-row items-center gap-4 md:gap-10 bg-white shadow-[0px_1px_5px_rgba(41,45,55,0.15)]">
      <div className="p-0 md:px-5 ">
        <p className="text-[14px] font-[400] lg:text-[16px] lg:font-[500] text-hubble_blue mb-0 lg:mb-1">
          {author}
        </p>
        <p className="text-[20px] font-[500] lg:text-[28px] text-hubble_blue mb-2 lg:mb-4">
          {projectName}
        </p>
        <p className="font-[400] text-[16px] lg:text-[14px] mb-0">{content}</p>
      </div>
      <img src={imgUrl} alt={projectName} className="h-[306px] w-[306px]" />
    </div>
  );
};

export default HorizontalReviewCard;
