import React from 'react';
import BenefitCard from './BenefitCard';

import { graphql, useStaticQuery } from 'gatsby';
import tw, { styled } from 'twin.macro';

const StrapiRichText = styled.div`
  p {
    margin-bottom: 0px;
  }
  strong {
    font-weight: 500;
  }
`;
const ComponentWrapper = tw.div`
mt-[60px] mb-[12px] lg:pb-[12px] text-base_text
`;
const MessageWrapper = tw.div`
px-[30px] lg:px-0 text-center
`;
const MobileHeader = tw.p`
md:hidden font-[500] text-[24px] mb-2
`;
const WebHeader = tw.h2`
hidden md:block mb-4
`;
const ListWrapper = tw.div`
mt-[24px] md:mt-[100px] flex flex-col lg:gap-4 justify-center items-center lg:items-start
`;
const Benefits = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          GoToMarketMessageDescription {
            data {
              GoToMarketMessageDescription
            }
          }
          GoToMarketMessageHeader
          GoToMarketUserBenefit {
            strapi_id
            SubHeader
            Description {
              data {
                Description
              }
            }
            image {
              url
            }
          }
        }
      }
    }
  `);
  const {
    GoToMarketMessageHeader,
    GoToMarketMessageDescription: {
      data: { GoToMarketMessageDescription },
    },
    GoToMarketUserBenefit,
  } = data.allStrapiLandingPage.nodes[0];

  return (
    <ComponentWrapper>
      <MessageWrapper>
        <WebHeader>{GoToMarketMessageHeader}</WebHeader>
        <MobileHeader>{GoToMarketMessageHeader}</MobileHeader>

        <StrapiRichText
          className="b2"
          dangerouslySetInnerHTML={{ __html: GoToMarketMessageDescription }}
        />
      </MessageWrapper>

      <ListWrapper>
        {GoToMarketUserBenefit.map((card: Record<string, any>) => {
          const {
            strapi_id: id,
            SubHeader: title,
            Description: {
              data: { Description: description },
            },
            image: { url: imgSrc },
          } = card;
          return (
            <BenefitCard
              key={id}
              title={title}
              description={description}
              imgSrc={imgSrc}
            />
          );
        })}
      </ListWrapper>
    </ComponentWrapper>
  );
};

export default Benefits;
