import React, { useCallback, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const ContentWrapper = tw.div`
  text-center pt-[60px] px-[30px] lg:px-0 lg:pt-[120px] flex flex-col gap-2
`;
const SubTitle = tw.p`
  text-[16px] lg:text-[18px] mb-2 leading-[150%] text-[#343A47]
`;
const Title = tw.p`
  text-[24px] font-[500] lg:text-[36px] lg:font-[400] text-base_text mb-4 lg:mb-[60px] text-base_text
`;
const Video = tw.video`
  w-full max-h-[420px] min-h-[168px] object-cover
`;

const Environment = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          ESGHeader
          ESGSubHeader
          ESGVideo {
            url
          }
          ESGBackgroundImage {
            url
          }
        }
      }
    }
  `);
  const {
    ESGHeader,
    ESGSubHeader,
    ESGVideo: { url: videoUrl },
    ESGBackgroundImage: { url: backgroundImage },
  } = data.allStrapiLandingPage.nodes[0];
  const videoRef = useRef<HTMLVideoElement>(null);

  const heightOfWindow = typeof window !== 'undefined' ? window.innerHeight : 0;
  const heightOfNavBar = 84;

  const handleScroll = useCallback(async () => {
    const offsetTopOfVideo = videoRef?.current?.offsetTop ?? 0;
    const heightOfVideo = videoRef?.current?.offsetHeight ?? 0;
    const show =
      window.scrollY > offsetTopOfVideo - heightOfWindow &&
      window.scrollY < offsetTopOfVideo + heightOfVideo - heightOfNavBar;
    try {
      if (videoRef.current) {
        if (show) {
          await videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    } catch (error) {}
  }, [videoRef.current]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <ContentWrapper>
        <SubTitle>{ESGSubHeader}</SubTitle>
        <Title>{ESGHeader}</Title>
      </ContentWrapper>

      <Video
        data-testid="environment-video"
        loop
        muted
        ref={videoRef}
        style={{
          background: `url('${backgroundImage}') no-repeat center center/cover`,
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </Video>
    </div>
  );
};

export default Environment;
