import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const ComponentWrapper = tw.div`
flex gap-6 flex-col lg:flex-row lg:justify-evenly lg:items-center py-10 lg:py-6
`;
const Header = tw.p`
text-[24px] font-[500] text-white self-start lg:self-center mb-0 lg:w-[400px] lg:text-center
`;
const LogoWrapper = tw.div`
flex flex-wrap lg:gap-[48px] lg:gap-y-4 gap-4 justify-start lg:justify-evenly 
`;
const Certification = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          CertificationImage {
            url
            id
          }
          CertificationHeader
        }
      }
    }
  `);
  const { CertificationImage, CertificationHeader } =
    data.allStrapiLandingPage.nodes[0];
  return (
    <ComponentWrapper>
      <Header>{CertificationHeader}</Header>
      <LogoWrapper>
        {CertificationImage.map(({ url, id }: Record<string, string>) => (
          <img
            key={id}
            src={url}
            alt="logo"
            className="max-h-[56px] object-contain"
          />
        ))}
      </LogoWrapper>
    </ComponentWrapper>
  );
};

export default Certification;
