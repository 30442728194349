import React from 'react';
import { USPType } from './type';
import USPBlogCard from './USPBlogCard';
import tw from 'twin.macro';

interface USPCardProps extends USPType {
  index: number;
}

const ComponentWrapper = tw.div`
  grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-[40px] justify-center items-center lg:h-fit 
`;
const TextWrapper = tw.div`
  text-base_text pt-6 px-[30px] mb-6
`;
const USPCard = ({
  imgUrl,
  subTitle,
  title,
  description,
  blogTitle,
  blogDescription,
  blogUrl,
  index,
  btn,
}: USPCardProps) => {
  const imgOnTopCSS = index % 2 === 0 ? '' : 'lg:hidden';
  const imgOnBottomCSS = index % 2 === 0 ? 'hidden' : 'hidden lg:inline-block';
  return (
    <ComponentWrapper>
      <div
        className={`h-[233px] w-full px-[30px] lg:h-full lg:px-0 ${imgOnTopCSS}`}
      >
        <div
          style={{
            background: `url(${imgUrl}) no-repeat center center /contain`,
            height: `100%`,
          }}
          data-testid="USP-card-top_img"
        />
      </div>

      <div>
        <TextWrapper>
          <p className="text-[14px] lg:text-[18px] mb-1 font-[500] text-accent_blue_2">
            {subTitle}
          </p>
          <p className="text-[24px] font-[500] text-base_text mb-4 lg:mb-2 leading-[150%]">
            {title}
          </p>
          <p className="whitespace-pre-line text-[14px] lg:text-[16px] text-base_text leading-[175%] m-0">
            {description}
          </p>
        </TextWrapper>

        <USPBlogCard
          blogTitle={blogTitle}
          blogDescription={blogDescription}
          blogUrl={blogUrl}
          btn={btn}
        />
      </div>

      <div
        style={{
          background: `url(${imgUrl}) no-repeat top center/contain`,
          height: '100%',
        }}
        className={imgOnBottomCSS}
        data-testid="USP-card-bottom_img"
      />
    </ComponentWrapper>
  );
};

export default USPCard;
