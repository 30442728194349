import React from 'react';
import tw, { styled } from 'twin.macro';
import { BenefitCardType } from './type';

interface BenefitCardProps extends BenefitCardType {}

const CardWrapper = tw.div`
max-w-[408px] lg:max-w-full lg:w-full grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-[80px] px-[30px] lg:px-0 lg:mb-[48px] lg:items-center
`;
const ContentWrapper = tw.div`
mt-4 mb-12 lg:my-0 max-w-[480px]
`;
const Title = tw.p`
text-[20px] lg:text-[24px] lg:font-[500] text-base_text mb-4 lg:mb-6
`;
const Description = styled.div`
  ${tw`text-[14px] text-[16px] leading-[145%]`}
  strong {
    font-weight: 500;
  }
`;
const BenefitCard = ({ imgSrc, title, description }: BenefitCardProps) => {
  return (
    <CardWrapper>
      <img
        src={imgSrc}
        alt={title}
        className="h-[234px] w-[100%] object-contain"
      />
      <ContentWrapper>
        <Title>{title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </ContentWrapper>
    </CardWrapper>
  );
};

export default BenefitCard;
