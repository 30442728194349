import React from 'react';
import ReviewBtnList from '../components/ReviewBtnList';
import LinkBtn from '../components/LinkBtn';
import HorizontalReviewCard from '../components/HorizontalReviewCard';
import CustomerCarousel from '../components/CustomerCarousel';
import { NewsroomTestimonialContentType } from '../types';

interface TestimonialCarouselProps extends NewsroomTestimonialContentType {}

const TestimonialCarousel = ({
  cardList,
  title,
  description,
  btnLabel,
  btnLink,
  reviewBtnLabel,
}: TestimonialCarouselProps) => {
  return (
    <div className="px-[30px] pt-[60px] pb-[40px] lg:pb-[60px]">
      <div className="flex flex-col md:flex-row md:justify-between gap-6 mb-4 lg:mb-[60px] md:my-15">
        <div>
          <p className="b2 text-subtext mb-0 md:mb-2">{description}</p>
          <h3 className="m-0 text-base_text mb-4">{title}</h3>
          <LinkBtn to={btnLink} label={btnLabel} />
        </div>
        <ReviewBtnList
          containerStyle={{ flexDirection: 'column', alignItems: 'start' }}
          reviewBtnLabel={reviewBtnLabel}
        />
      </div>
      <CustomerCarousel>
        {cardList.map((card, index) => (
          <div key={index} className="h-[700px] md:h-[430px]">
            <HorizontalReviewCard {...card} />
          </div>
        ))}
      </CustomerCarousel>
    </div>
  );
};

export default TestimonialCarousel;
